import * as Automerge from "@automerge/automerge";
import * as UUID from "uuid";
import * as Monaco from "monaco-editor";

import * as Model from "wolf-common";
import LocalStorage from "./local";
import { RemoteStorage } from "./remote";
import { findInsertedInterval, getEditOperation } from "../editor";

export class ChangeManager {
    userId: string;
    docId: string;

    private readonly local: LocalStorage;
    private readonly remote: RemoteStorage;
    private doc: Automerge.Doc<Model.Content>;

    constructor(serverUri: string, userId: string, docId: string) {
        this.userId = userId;
        this.docId = docId;
        this.local = new LocalStorage(userId, docId);
        this.remote = new RemoteStorage(serverUri, userId, docId);

        // TODO(muvaf): This is a hack to prevent compilation error.
        //  We always override it in initialize anyway.
        this.doc = Automerge.init<Model.Content>();
    }

    // initialize will initialize the document from local storage and remote. If
    // the local has more changes, then it will merge them on top of the remote
    // and return the final text.
    async initializeLocal(): Promise<string> {
        const initDoc = await this.local.initialize()
        const [newDoc] = Automerge.applyChanges<Model.Content>(
            Automerge.init<Model.Content>(),
            initDoc.changes.map((c) => c.change)
        );
        this.doc = newDoc;
        return this.doc.text.toString();
    }

    async initializeRemote(): Promise<string> {
        const localChanges = await this.local.getAllChanges()
        const remoteDocument = await this.remote.initialize(this.docId)

        const remoteMissingChanges = remoteDocument.changes
            .filter((c: Model.Change) => this.userId !== c.userId)
            .filter((remoteChange: Model.Change) =>
                localChanges.some((c: Model.Change) => c.uid === remoteChange.uid)
            );
        if (remoteMissingChanges.length === 0) {
            return this.doc.text.toString();
        }
        const [newDoc] = Automerge.applyChanges<Model.Content>(
            this.doc,
            remoteMissingChanges.map((change: Model.Change) => change.change)
        );
        this.doc = newDoc;
        return this.doc.text.toString();
    }

    // applyText applies the given text to the local storage and remote storage
    // if necessary, and it returns true only if the change is sent to the final
    // destination.
    async applyText(newText: string): Promise<boolean> {
        const change = this.generateChange(newText);
        if (change === null) {
            return false;
        }
        await this.local.saveChanges([change]);
        const allLocalChanges = await this.local.getAllChanges();
        const unsyncedChanges = allLocalChanges.filter((c) => c.userId === this.userId && !c.syncedInRemote);
        const appliedChanges = await this.remote.applyChanges(unsyncedChanges);
        return appliedChanges.length !== 0
    }

    generateChange(newText: string): Model.Change | null {
        if (this.doc.text.toString() === newText) {
            return null
        }
        this.doc = Automerge.change<Model.Content>(this.doc, (content: Model.Content) => {
            // NOTE(muvaf): Setting content.text directly causes too much churn for Automerge and is inefficient.
            // By working with specific diffs, changes have more ops but they don't need to carry the whole text anymore
            // and the diff calculations are faster.

            // TODO(muvaf): "content" cannot be changed outside an Automerge.change block but we need to find a way
            //  to test these manipulations.
            switch (true) {
                case newText.length > content.text.length:
                    const [additionBegin, additionEnd] = findInsertedInterval(content.text.toString(), newText);
                    content.text.insertAt(additionBegin, ...newText.slice(additionBegin, additionEnd));
                    break;
                case newText.length < content.text.length:
                    const [deletionBegin, deletionEnd] = findInsertedInterval(newText, content.text.toString());
                    content.text.deleteAt(deletionBegin, deletionEnd - deletionBegin);
                    break;
                case newText.length === content.text.length:
                    const [replacementBegin, replacementEnd] = findInsertedInterval(
                        content.text.toString(),
                        newText
                    );
                    content.text.deleteAt(replacementBegin, replacementEnd - replacementBegin);
                    content.text.insertAt(replacementBegin, ...newText.slice(replacementBegin, replacementEnd));
                    break;
            }
        });
        return {
            uid: UUID.v4(),
            change: Automerge.getLastLocalChange(this.doc),
            userId: this.userId,
            syncedInRemote: false,
        } as Model.Change
    }

    // onNewChanges will be called when there are new changes from the remote
    // storage.
    async register(newChangesFn: (edit: Monaco.editor.IIdentifiedSingleEditOperation) => void): Promise<void> {
        return this.remote.register((changes: Model.Change[]) => {
            return this.local.saveChanges(changes).then((savedChanges: Model.Change[]) => {
                const foreignChanges = savedChanges.filter((c) => c.userId !== this.userId);
                if (foreignChanges.length === 0) {
                    return Promise.resolve();
                }
                const [newDoc] = Automerge.applyChanges<Model.Content>(
                    this.doc,
                    foreignChanges.map((change: Model.Change) => change.change)
                );
                const edit = getEditOperation(this.doc.text.toString(), newDoc.text.toString());
                this.doc = newDoc;
                newChangesFn(edit);
            });
        });
    }

    async isAllSynced(): Promise<boolean> {
        const allChanges = await this.local.getAllChanges()
        return allChanges.filter((c: Model.Change) => !c.syncedInRemote).length === 0
    }
}
