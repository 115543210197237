import * as Monaco from "monaco-editor";
import { findInsertedInterval, findPosition } from "./strings";

// GetEditOperation returns a single Monaco Editor edit operation that
// can transform given old text into new text.
export function getEditOperation(oldText: string, newText: string): Monaco.editor.IIdentifiedSingleEditOperation {
    let monacoEdit = {} as Monaco.editor.IIdentifiedSingleEditOperation;
    switch (true) {
        case newText.length > oldText.length: {
            const [additionBegin, additionEnd] = findInsertedInterval(oldText, newText);
            const startPos = findPosition(oldText, additionBegin);
            monacoEdit.range = new Monaco.Range(startPos[0], startPos[1], startPos[0], startPos[1]);
            monacoEdit.text = newText.slice(additionBegin, additionEnd);
            monacoEdit.forceMoveMarkers = true;
            break;
        }
        case newText.length < oldText.length: {
            const [deletionBegin, deletionEnd] = findInsertedInterval(newText, oldText.toString());
            const startPos = findPosition(oldText, deletionBegin);
            const endPos = findPosition(oldText, deletionEnd);
            monacoEdit.range = new Monaco.Range(startPos[0], startPos[1], endPos[0], endPos[1]);
            monacoEdit.text = null;
            monacoEdit.forceMoveMarkers = true;
            break;
        }
        case newText.length === oldText.length: {
            if (newText === oldText) {
                break;
            }
            const [replacementBegin, replacementEnd] = findInsertedInterval(oldText.toString(), newText);
            const startPos = findPosition(oldText, replacementBegin);
            const endPos = findPosition(oldText, replacementEnd);
            monacoEdit.range = new Monaco.Range(startPos[0], startPos[1], endPos[0], endPos[1]);
            monacoEdit.text = newText.slice(replacementBegin, replacementEnd);
            break;
        }
    }
    return monacoEdit;
}
