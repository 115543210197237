import React from "react";

import "./App.css";

export function Login() {
    return (
        <form method="get" action="/documents">
            <label>Document Name</label>
            <input name="docId" type="text" placeholder="default" />
            <label>User Name</label>
            <input name="userId" type="text" placeholder="john" />
            <button type="submit">Enter</button>
        </form>
    );
}
