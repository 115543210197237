import * as Automerge from "@automerge/automerge";
import * as Model from "./index";

// Document is the in-memory representation of a whole document.
export interface Document {
    id: string;
    initChange: Automerge.Change;
    changes: Change[];
}

// TODO(muvaf): Look into using Automerge.Text type.

// Content is what's stored in a document.
export interface Content {
    text: Automerge.Text;
}

// Change is the smallest unit of change in a document.
// Note that Automerge guarantees that the order is not important to reach
// the same document, but it's relevant to keep track of the history.
export interface Change {
    uid: string;
    userId: string;
    change: Automerge.Change;
    syncedInRemote: boolean;
}

// DocumentZero is the initial state of a document. All documents should start
// with this state so that the merge operations succeed.
// See https://automerge.org/docs/cookbook/modeling-data/#setting-up-an-initial-document-structure
export const DocumentZero = function (docId: string): Model.Document {
    const schema = Automerge.change<Model.Content>(
        Automerge.init<Model.Content>({ actor: "0000" }),
        { time: 0 },
        (content: Model.Content) => {
            content.text = new Automerge.Text("# Welcome to Wolf Editor!");
        }
    );
    return {
        id: docId,
        initChange: Automerge.getLastLocalChange(schema)!,
    } as Model.Document;
};
