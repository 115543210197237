import * as SocketIOClient from "socket.io-client";
import * as Automerge from "@automerge/automerge";

import * as Model from "wolf-common";

// RemoteStorage is the last local layer of storage where it sends the changes to the
// remote socket server and accepts incoming packets.
export class RemoteStorage {
    private readonly userId: string;
    private readonly docId: string;
    private readonly socket: SocketIOClient.Socket<Model.ServerToClientEvents, Model.ClientToServerEvents>;
    private incomingChangeQueue: Model.Change[];

    private lastIncomingProcessTime: number = 0;
    private incomingProcessInterval: number = 3000;

    constructor(uri: string, userId: string, docId: string) {
        this.userId = userId;
        this.docId = docId;
        this.incomingChangeQueue = [];
        this.socket = SocketIOClient.connect(uri);
        this.socket.on("connect", () => {
            console.log(`Connected to the server on ${uri}`);
            this.socket.on("disconnect", () => {
                console.log(`Disconnected from the server on ${uri}`);
            });
        });
    }

    // initialize joins the document in the server and requests all changes.
    async initialize(docId: string): Promise<Model.Document> {
        return new Promise<Model.Document>((resolve, _) => {
            this.socket.once("allChanges", (changes: Model.Change[]) => {
                console.log(`Received all ${changes.length} changes of ${docId} from the server`);
                // NOTE(muvaf): Socket.io converts Uint8Array to ArrayBuffer, and we need
                // to convert it back.
                const allChanges = changes.map((c) => {
                    c.change = new Uint8Array(c.change);
                    return c;
                });
                const doc = Model.DocumentZero(docId);
                doc.changes = allChanges;
                resolve(doc);
            });
            this.socket.emit("join", this.userId, this.docId);
            console.log(`Requested to join the document ${this.docId} as ${this.userId}`);
        });
    }

    // applyChanges sends the changes to the server and returns the ones that are
    // sent - the changes of other users and synced changes are filtered out.
    async applyChanges(changes: Model.Change[]): Promise<Model.Change[]> {
        const toSend = changes.filter((c) => !c.syncedInRemote && c.userId === this.userId);
        if (toSend.length === 0) {
            return [];
        }
        this.socket.emit("newChanges", toSend);
        return toSend;
    }

    // TODO(muvaf): Call onNewChanges periodically to flush the queue if no change
    //  is coming for a while.

    // onNewChanges registers a callback that is called when the server sends
    // new changes. It doesn't immediately call the callback, it queues the changes
    // to prevent overloading and jitter.
    async register(newChangesFn: (changes: Model.Change[]) => void): Promise<void> {
        this.socket.on("newChanges", (changes: Model.Change[]) => {
            this.incomingChangeQueue.push(
                ...changes.map((c) => {
                    c.change = new Uint8Array(c.change);
                    return c;
                })
            );
            if (Date.now() - this.lastIncomingProcessTime <= this.incomingProcessInterval) {
                return;
            }
            this.lastIncomingProcessTime = Date.now();
            const cutOff = this.incomingChangeQueue.length;
            const toProcess = this.incomingChangeQueue.slice(0, cutOff);
            this.incomingChangeQueue = this.incomingChangeQueue.slice(cutOff);
            newChangesFn(toProcess);
        });
        return;
    }
}
