import React from "react";
import * as UUID from "uuid";
import * as MonacoReact from "@monaco-editor/react";
import Editor from "@monaco-editor/react";
import * as Monaco from "monaco-editor";
import { Radio } from "react-loader-spinner";
import "./App.css";

import { ChangeManager } from "./pipeline/manager";

const WolfServer = process.env.REACT_APP_DOCUMENT_SERVER_URI || "ws://localhost:9000";

let manager: ChangeManager;

const maxWaitInterval = 1000;
const minApplyInterval = 1000;
let lastApplyTime = 0;

type SyncState = {
    synced: boolean;
};

export function App() {
    const [sync, setSync] = React.useState<SyncState>({ synced: false });

    function handleEditorChange(value: string | undefined, _: Monaco.editor.IModelContentChangedEvent) {
        if (!value) {
            return;
        }
        setSync({ synced: false });
        if (Date.now() - lastApplyTime <= minApplyInterval) {
            return
        }
        lastApplyTime = Date.now();
        manager.applyText(value).then((sent: boolean) => {
            if (sent) {
                setSync({ synced: false });
            }
        });
    }

    async function handleEditorDidMount(editor: Monaco.editor.IStandaloneCodeEditor, _: MonacoReact.Monaco) {
        const url = new URLSearchParams(window.location.search);
        const docId = url.get("docId") || "default";
        const userId = url.get("userId") || UUID.v4().toString();
        manager = new ChangeManager(WolfServer, userId, docId);
        const localText = await manager.initializeLocal()
        editor.setValue(localText)
        const remoteText = await manager.initializeRemote()
        editor.setValue(remoteText)
        await manager.register((edit: Monaco.editor.IIdentifiedSingleEditOperation): void => {
            manager.isAllSynced().then((synced: boolean) => setSync({synced: synced}));
            if (edit) {
                editor.executeEdits("server", [edit]);
            }
        })
        setInterval(() => {
            manager.isAllSynced().then((synced: boolean) => setSync({ synced: synced }));
            if (Date.now() - lastApplyTime > minApplyInterval) {
                lastApplyTime = Date.now();
                manager.applyText(editor.getValue()).then((sent: boolean) => {
                    if (sent) {
                        setSync({ synced: false });
                    }
                });
            }
        }, maxWaitInterval);
    }
    return (
        <div className="App">
            <header className="App-header" title="Wolf Editor">
                Wolf Editor
                <Radio
                    colors={sync.synced ? ["#00FF00", "#00FF00", "#00FF00"] : ["#FF0000", "#FF0000", "#FF0000"]}
                    ariaLabel="puff-loading"
                    visible={true}
                />
                <Editor
                    height="90vh"
                    defaultLanguage="markdown"
                    defaultValue="# Hello World"
                    onChange={handleEditorChange}
                    onMount={handleEditorDidMount}
                    theme={"vs-dark"}
                />
            </header>
        </div>
    );
}
