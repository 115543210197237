// findIndex takes the position of the cursor in the text and returns the index
// of the character on the right of the cursor, taking into account that the
// position is 1-based not 0-based as with the index.
export function findIndex(text: string, position: [number, number]): number {
    const charsInLinesBefore = text
        .split("\n")
        .slice(0, position[0] - 1)
        .reduce((acc, line) => acc + line.length + 1, 0);
    return charsInLinesBefore + position[1] - 1;
}

// findPosition takes the index and returns the line and column number.
export function findPosition(text: string, index: number): [number, number] {
    const textBefore = text.slice(0, index);
    const lineNumber = textBefore.split("\n").length;
    const column = index - textBefore.lastIndexOf("\n");
    return [lineNumber, column];
}

// findInsertedInterval takes two strings and returns the interval of the
// inserted text. Beginning is inclusive, end is exclusive, i.e. [begin, end).
export function findInsertedInterval(oldText: string, newText: string): [number, number] {
    if (oldText.length >= newText.length) {
        return [0, 0];
    }
    let begin = 0;
    let end = 0;
    for (let i = 0; i < oldText.length; i++) {
        begin = i;
        const oldChar = oldText[i];
        const newChar = newText[i];
        if (oldChar !== newChar) {
            break;
        }
    }
    // Appended to the old text.
    if (begin === oldText.length - 1) {
        return [begin + 1, newText.length];
    }
    for (let i = newText.length - 1; i >= newText.length - oldText.length; i--) {
        end = i + 1;
        // Only a single character is inserted.
        if (i === begin) {
            break;
        }
        const oldI = i - (newText.length - oldText.length);
        const oldChar = oldText[oldI];
        const newChar = newText[i];
        if (oldChar !== newChar) {
            break;
        }
    }
    return [begin, end];
}
