import * as LocalForage from "localforage";

import * as Model from "wolf-common";
import * as Automerge from "@automerge/automerge";
import * as UUID from "uuid";

// LocalStorage is the storage layer in the browser that can keep the changes
// between sessions.
export default class LocalStorage {
    userId: string;
    docId: string;
    constructor(userId: string, docId: string) {
        this.userId = userId;
        this.docId = docId;
    }

    // initialize first checks whether the document exists in the local storage,
    // if not, it initializes a zero document with given id.
    async initialize(): Promise<Model.Document> {
        const allLocalChanges = await this.getAllChanges();
        if (allLocalChanges.length === 0) {
            const [initDoc] = Automerge.applyChanges<Model.Content>(Automerge.init<Model.Content>(), [
                Model.DocumentZero(this.docId).initChange,
            ]);
            const initChange = Automerge.getAllChanges(initDoc).map((change) => {
                return {
                    uid: UUID.v4(),
                    change: change,
                    userId: this.userId,
                    syncedInRemote: false,
                } as Model.Change;
            })
            allLocalChanges.push(...initChange);
        }
        const doc = Model.DocumentZero(this.docId);
        doc.changes = allLocalChanges;
        return doc;
    }

    // saveChanges writes all changes to the local storage.
    async saveChanges(changes: Model.Change[]): Promise<Model.Change[]> {
        changes.map(async (c) => {
            await LocalForage.setItem(`${this.docId}/${c.uid}`, c);
        })
        return changes
    }

    // getAllChanges returns all changes that are stored in the local storage.
    async getAllChanges(): Promise<Model.Change[]> {
        const keys = await LocalForage.keys()
        const allDocKeys = keys.filter((k) => k.startsWith(`${this.docId}/`))
        const allChanges = [] as Model.Change[]
        for (const k of allDocKeys) {
            const change = await LocalForage.getItem<Model.Change>(k);
            if (!change) {
                allChanges.push(change!);
            }
        }
        return allChanges;
    }
}
